import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";

import { CustomEventType, EventType, DocumentType } from "../../Common/Enums";

import { FacultyQuestDTO, FacultyDTO, QuestionnaireDTO } from "../../Models";
import { CustomEventHandlerService, HCPFileService, HCPQuestionnaireService } from "../../Services";

import FRCard from "../../Components/Card/FRCard";
import QuestionnaireForm from "../../Components/Questionnaire/QuestionnaireForm";
import QuestionnaireUtils from "../../Common/QuestionnaireUtils";
import Alert from "../../Components/Alert/Alert";

interface Props {
    faculty?: FacultyDTO;
    onSubmitSuccess: (() => void);
}
interface State {
    questionnaire?: QuestionnaireDTO;
    isLoading: boolean;
}
class ProfessionalProfileQuestionnaire extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            questionnaire: undefined,
            isLoading: true,
        };
    }

    async componentDidMount() {
        const { faculty } = this.props;

        if (faculty) {
            const questionnaire = await HCPQuestionnaireService.getAll(faculty.facultyid);
            this.setState({ questionnaire, isLoading: false });
        }
    }

    render() {
        const { questionnaire } = this.state;

        return (
            <FRCard title="Professional Profile Questionnaire">
                <Grid container>
                    <Grid item md={12}>
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Typography style={{ fontSize: 12, fontWeight: 500 }}>
                                    There are total 20+ dynamically populated questions as per your selection. Appreciate your patience.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={12}>
                        <Box p={2} style={{ marginBottom: 10 }} data-html2canvas-ignore>
                            <Alert />
                        </Box>
                    </Grid>
                    <Grid item md={12}>
                        {questionnaire ?
                            <QuestionnaireForm isFR questionnaire={questionnaire}
                                onSave={this.handleSaveAnswer} onSubmit={this.handleSubmitAnswer} />
                            : null}
                    </Grid>
                </Grid>
            </FRCard>
        );
    }

    handleSaveAnswer = async (answer: FacultyQuestDTO, eventType: EventType) => {
        const { questionnaire } = this.state;

        if (questionnaire) {
            const updatedQuestionnaire = await HCPQuestionnaireService.handleSaveAnswer(questionnaire, answer, eventType);

            this.setState({ isLoading: true }, () => {
                this.setState({ questionnaire: updatedQuestionnaire, isLoading: false });
            });
        }
    }

    handleSubmitAnswer = async () => {
        const { questionnaire } = this.state;
        const { faculty } = this.props;

        if (questionnaire && faculty) {
            const isQuestionnaireValid = QuestionnaireUtils.validateQuestionnaire(questionnaire);

            if (isQuestionnaireValid) {
                const facultyFiles = await HCPFileService.getAll(faculty.facultyid);
                const cvFiles = facultyFiles.filter(file => file.documenttype === DocumentType.CV).sort((a, b) => {
                    if (a.createdon && b.createdon) {
                        return new Date(b.createdon).valueOf() - new Date(a.createdon).valueOf();
                    }

                    return -1;
                });

                if (cvFiles.length > 0) {
                    const cvFile = cvFiles[0];
                    const fileMetaData = await HCPFileService.getMetaData(cvFile.s3filekey);

                    if (fileMetaData === null) {
                        CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, "There is an error while uploading CV. Please try again by deleting and uploading CV with correct size and format.");
                    } else {
                        const isSuccess = await HCPQuestionnaireService.handleSubmitAnswer(questionnaire);

                        if (isSuccess) {
                            const questionnaire: QuestionnaireDTO = {
                                facultyId: faculty.facultyid,
                                questionnaire: [],
                                facultyQuests: [],
                            };
                            this.setState({ questionnaire }, () => {
                                this.props.onSubmitSuccess();
                            });
                        }
                    }
                } else {
                    CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, "CV is mandatory. Please upload CV before submitting.");
                }
            } else {
                CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, "All questions are mandatory. Please answer all questions before submitting.");
            }
        }
    }
}

export default ProfessionalProfileQuestionnaire;