import React from "react";
import { Grid } from "@material-ui/core";

import { ADMINFileService, CustomEventHandlerService } from "../../../Services";
import { FacultyDTO } from "../../../Models";
import { FileType, DocumentType, CustomEventType } from "../../../Common/Enums";
import Utils from "../../../Common/Utils";

import DetailCard from "../../../Components/Card/DetailCard";
import FileUploadModel from "../../../Components/File/FileUploadModel";
import FileUpload from "../../../Components/File/FileUpload";
import FileUploadCard from "../../../Components/File/FileUploadCard";
import IconButton from "../../../Components/Button/IconButton";
import WhiteButton from "../../../Components/Button/WhiteButton";
import Alert from "../../../Components/Alert/Alert";

interface Props {
    selectedFaculty?: FacultyDTO;
}
interface State {
    files: FileUploadModel[];
    show: 'ADD' | 'EDIT' | 'NONE';
}
class DocumentUpload extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            files: [],
            show: 'NONE',
        };
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.selectedFaculty) {
            if (this.props.selectedFaculty.facultyid !== (prevProps.selectedFaculty && prevProps.selectedFaculty.facultyid)) {
                this.loadData();
            }
        } else {
            const { files } = this.state;

            if (files.length > 0) {
                this.setState({
                    files: [],
                    show: 'NONE',
                });
            }
        }
    }

    render() {
        const { selectedFaculty } = this.props;
        const { files, show } = this.state;

        if (selectedFaculty) {
            return (
                <DetailCard title="Documents Upload"
                    titleElement={
                        show === 'NONE' ? <>
                            <IconButton iconType="Edit" onClick={this.handleEditClick} />
                            <IconButton iconType="Add" onClick={this.handleAddClick} />
                        </> : null
                    }>
                    <Alert id="documentUpload" data-html2canvas-ignore />
                    <Grid container direction="row" spacing={2}>
                        {show === 'ADD' ? <Grid item md={12}>
                            <FileUpload isDocumentUpload onChange={this.handleFileUpload} />
                        </Grid> : null}
                        <Grid item md={12}>
                            <FileUploadCard files={files} showDelete={show === 'EDIT'}
                                onDelete={this.handleFileDelete} onDownload={this.handleFileDownload} />
                        </Grid>
                    </Grid>
                    {show !== 'NONE' ? <Grid container spacing={2} justify="flex-end" style={{ marginTop: 30 }} data-html2canvas-ignore>
                        <Grid item>
                            <WhiteButton label="Cancel" onClick={this.handleCancelClick} />
                        </Grid>
                    </Grid> : null}
                </DetailCard>
            );
        }

        return null;
    }

    loadData = async () => {
        const { selectedFaculty } = this.props;

        if (selectedFaculty) {
            const facultyFiles = await ADMINFileService.getAll(selectedFaculty.facultyid, DocumentType.OTHER);

            const files: FileUploadModel[] = [];

            facultyFiles.filter(file => file.documenttype === DocumentType.OTHER).forEach((facultyFile) => {
                const file = new FileUploadModel();

                file.id = facultyFile.id;
                file.name = facultyFile.filename;
                file.facultyId = facultyFile.facultyid;
                file.type = FileType[facultyFile.documenttype as keyof typeof FileType];
                file.s3filekey = facultyFile.s3filekey;
                file.sizeInKB = facultyFile.fileSizeInKB ?? 0;

                files.push(file);
            });

            this.setState({ files });
        }
    }

    handleEditClick = () => {
        this.setState({ show: 'EDIT' });
    }

    handleAddClick = () => {
        this.setState({ show: 'ADD' });
    }

    handleCancelClick = () => {
        this.setState({ show: 'NONE' });
    }

    handleFileUpload = async (file: FileUploadModel) => {
        const isFileSizeValid = Utils.validateFileSize(file.sizeInMB, DocumentType.OTHER);
        const isFileTypeValid = Utils.validateFileType(file.type, DocumentType.OTHER);
        let message = '';

        if (isFileSizeValid && isFileTypeValid) {
            const { selectedFaculty } = this.props;

            if (selectedFaculty) {
                file.facultyId = selectedFaculty.facultyid;

                const facultyFile = await ADMINFileService.uploadFile(file, DocumentType.OTHER);

                if (facultyFile) {
                    const fileUploadDTO = new FileUploadModel();

                    fileUploadDTO.id = facultyFile.id;
                    fileUploadDTO.name = facultyFile.filename;
                    fileUploadDTO.facultyId = facultyFile.facultyid;
                    fileUploadDTO.type = FileType[facultyFile.documenttype as keyof typeof FileType];
                    fileUploadDTO.s3filekey = facultyFile.s3filekey;
                    fileUploadDTO.sizeInKB = file.sizeInKB;
                    fileUploadDTO.sizeInMB = file.sizeInMB;

                    this.setState(prevState => ({
                        files: [...prevState.files, fileUploadDTO],
                    }));

                    message = 'Document uploaded successfully';
                    CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, { alertId: 'documentUpload', message });
                }
            }
        } else {
            if (!isFileSizeValid) {
                message = 'Invalid file size';
            }
            if (!isFileTypeValid) {
                message = 'Invalid file type';
            }

            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, { alertId: 'documentUpload', message });
        }
    }

    handleFileDownload = async (file: FileUploadModel) => {
        const downloadURL = await ADMINFileService.getFile(file.s3filekey, file.type);
        Utils.openUrlParent(downloadURL);
    }

    handleFileDelete = async (file: FileUploadModel) => {
        const isSuccess = await ADMINFileService.deleteFile(file, DocumentType.OTHER);
        let message = '';

        if (isSuccess) {
            const { files } = this.state;

            const index = files.findIndex(p => p.name === file.name && p.type === file.type);
            files.splice(index, 1);

            this.setState({ files }, () => {
                const fileInput = document.getElementById("otherUpload") as HTMLInputElement;

                if (fileInput) {
                    fileInput.value = '';
                }
            });

            message = 'Document deleted successfully';
            CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, { alertId: 'documentUpload', message });
        } else {
            message = 'Failed to delete Document';
            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, { alertId: 'documentUpload', message });
        }
    }
}

export default DocumentUpload;