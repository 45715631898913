export enum FieldType {
  SingleCheckbox,
  MultiCheckbox,
  SingleDropdown,
  MultiDropdown,
  Radiobutton,
  Logicstep,
  Textbox,
}

export enum EventType {
  ADD,
  REMOVE
}

export enum CustomEventType {
  SuccessAlert,
  ErrorAlert,
  ClearAlert,
  LoadingStart,
  LoadingStop,
}

export enum ConditionType {
  EQUAL = 'eq',
  GREATER_THAN = 'greaterthan',
  IN = 'in',
  JSON = 'json',
  NOT_EQUAL = 'noteq',
}

export enum SortType {
  ALPHA = 'alpha',
}

export enum FileType {
  PDF = "application/pdf",
  DOC = "application/msword",
  DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
}

export enum DocumentType {
  CV = "cv",
  PPQPDF = "ppqpdf",
  OTHER = "other",
  REQUESTER = "requester",
  APPROVER = "approver"
}

export enum QuestionnaryStatus {
  PENDING = 'PENDING',
  SUBMITTED = 'SUBMITTED',
  EXCEPTION = 'EXCEPTION',
}

export enum ExceptionType {
  PAYEREXCEPTION = 'PayerException',
  RATEEXCEPTION = 'RateException',
  PAYERANDRATEEXCEPTION = 'PayerAndRateException',
}

export enum ValidationType {
  NONE,
  REQUIRED,
  RATE,
}

export enum AnswerConditionType {
  REMOVEOTHER = 'REMOVEOTHER',
}

export enum UserRole {
  LILLY_ADMIN = 'LILLY_ADMIN',
  LILLY_USER = 'LILLY_USER',
  LILLY_HCP = 'LILLY_HCP',
}