import React from "react";
import moment from "moment";
import { Divider, Grid, Typography, Button } from "@material-ui/core";

import { ADMINFileService, AuditService } from "../../../Services";
import { AuditDTO, FacultyPPQDataDTO } from "../../../Models";
import DetailCard from "../../../Components/Card/DetailCard";
import { FileType } from "../../../Common/Enums";
import Utils from "../../../Common/Utils";

interface Props {
    facultyPPQData: FacultyPPQDataDTO;
}
interface State {
    audits: AuditDTO[];
}
class TieringDetails extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            audits: [],
        };
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.facultyPPQData) {
            if (this.props.facultyPPQData.facultyid !== (prevProps.facultyPPQData && prevProps.facultyPPQData.facultyid)) {
                this.loadData();
            }
        } else {
            const { audits } = this.state;

            if (audits.length > 0) {
                this.setState({
                    audits: [],
                });
            }
        }
    }

    render() {
        const { audits } = this.state;

        return (
            <DetailCard title="Tiering & Rate Change Details">
                <Grid container direction="row">
                    {audits.map((audit, i) => {
                        const actionDateTime =
                            `${moment(audit.actiondate).format('DD MMM YYYY')} | ${moment(audit.actiondate).format('LT')}`;

                        return (
                            <Grid item md={12} key={`AUDIT-${i}`}>
                                <Grid container direction="row" spacing={4}>
                                    <Grid item md={12}>
                                        <Typography style={{ fontSize: 16, fontWeight: 400, color: '#5D5D5D' }}>
                                            {actionDateTime}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={12}>
                                        <Grid container justify="space-between" spacing={4}>
                                            <Grid item md={6}>
                                                <Typography style={{ fontSize: 14, fontWeight: 400, color: '#5D5D5D' }}>
                                                    Action
                                                </Typography>
                                                <Typography style={{ fontSize: 16, fontWeight: 400, color: '#1A1A1A' }}>
                                                    {audit.action}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={4}>
                                                <Typography style={{ fontSize: 14, fontWeight: 400, color: '#5D5D5D' }}>
                                                    Action Taken by
                                                </Typography>
                                                <Typography style={{ fontSize: 16, fontWeight: 400, color: '#1A1A1A' }}>
                                                    {audit.actiontakenby}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={2}>
                                                <Button style={{ fontSize: 16, fontWeight: 500, color: '#EE3A29', textTransform: 'none' }}
                                                    onClick={() => this.onViewPDF(audit)}>
                                                    View PDF
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {(audits.length - 1 !== i) ?
                                    <Divider variant="fullWidth" style={{ marginTop: 15, marginBottom: 30 }} />
                                    : null
                                }
                            </Grid>
                        );
                    })}
                </Grid>
            </DetailCard>
        );
    }

    loadData = async () => {
        const { facultyPPQData } = this.props;
        const { id } = facultyPPQData;

        const audits = await AuditService.getAll(id);

        this.setState({ audits });
    }

    onViewPDF = async (audit: AuditDTO) => {
        const url = await ADMINFileService.getFile(audit.s3filekey, FileType.PDF);
        Utils.openUrlParent(url);
    }
}

export default TieringDetails;