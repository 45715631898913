import React from "react";
import { Grid, GridJustification } from "@material-ui/core";

import { EventType } from "../../Common/Enums";
import { QuestionnaireDTO, FacultyQuestDTO, ContentfulQuestion } from "../../Models";
import WhiteButton from "../Button/WhiteButton";
import RedButton from "../Button/RedButton";
import BlueButton from "../Button/BlueButton";
import QuestionnaireFormItem from "./QuestionnaireFormItem";
import QuestionnaireUtils from "../../Common/QuestionnaireUtils";

interface Props {
    questionnaire?: QuestionnaireDTO;
    isFR: boolean;
    onCancel?: (() => void);
    onSave: ((answer: FacultyQuestDTO, eventType: EventType) => void);
    onSubmit: (() => void);
}
interface State { }
class QuestionnaireForm extends React.Component<Props, State> {
    public static defaultProps = {
        isFR: false,
    }

    renderQuestion = (question: ContentfulQuestion, key: number) => {
        const { isFR, questionnaire, onSave } = this.props;

        if (questionnaire) {
            const { facultyQuests } = questionnaire;

            const answers = facultyQuests.filter(p => p.questionno === question.id);
            const isAnswered = answers.length > 0;
            const isActive = question.isActive;

            const prevFacultyQuests = facultyQuests.filter(p => p.questionno < question.id);
            const prevAnswerQuestion = prevFacultyQuests.length > 0 ? prevFacultyQuests.reduce((prev, current) =>
                (prev.questionno > current.questionno) ? prev : current) : null;
            const nextQuestions = QuestionnaireUtils.getNextQuestions(questionnaire, prevAnswerQuestion);

            let ignorePDF = false;

            if (question.config) {
                if (question.config.ignorePDF) {
                    ignorePDF = true;
                }
            }

            if (isAnswered) {
                return (
                    <Grid item md={12} key={`QF-${key}`} {...(ignorePDF && { "data-html2canvas-ignore": true })}>
                        <QuestionnaireFormItem isFR={isFR} questionnaire={questionnaire}
                            question={question} answers={answers} onAnswerChange={onSave} />
                    </Grid >
                );
            }

            const showQuestion = nextQuestions.some(p => p.id === question.id);

            if (showQuestion && isActive) {
                return (
                    <Grid item md={12} key={`QF-${key}`} {...(ignorePDF && { "data-html2canvas-ignore": true })}>
                        <QuestionnaireFormItem isFR={isFR} questionnaire={questionnaire}
                            question={question} answers={answers} onAnswerChange={onSave} />
                    </Grid>
                );
            }
        }

        return null;
    }

    render() {
        const { isFR, questionnaire, onCancel, onSubmit } = this.props;

        const isQuestionnaireValid = QuestionnaireUtils.validateQuestionnaire(questionnaire);

        const submitButton = isFR ? <BlueButton size="large" disabled={!isQuestionnaireValid} label="Submit" onClick={onSubmit} /> :
            <RedButton disabled={!isQuestionnaireValid} label="Submit" onClick={onSubmit} />;

        const gridButtonJustify: GridJustification = isFR ? 'flex-start' : 'flex-end';

        return (
            <>
                <Grid container justify="flex-start" spacing={7}>
                    {questionnaire && questionnaire.questionnaire.map(this.renderQuestion)}
                </Grid>
                <Grid container spacing={isFR ? 0 : 2} justify={gridButtonJustify} style={{ marginTop: isFR ? 40 : 30 }} data-html2canvas-ignore>
                    <Grid item>
                        {isFR ? null :
                            <WhiteButton label="Cancel" onClick={onCancel ? onCancel : () => { }} />}
                    </Grid>
                    <Grid item style={{ marginTop: questionnaire && questionnaire.questionnaire.length > 0 ? 0 : 10 }}>
                        {submitButton}
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default QuestionnaireForm;