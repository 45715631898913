import ProQuestAPIService from "./ProQuestAPIService";
import CustomEventHandlerService from './CustomEventHandlerService';

import { FacultyPPQDataDTO, BulkPPQResponseDTO, QuestionnaireManagementFacultyDTO } from "../Models";
import SelectModel from '../Components/Select/SelectModel';
import { CustomEventType } from '../Common/Enums';

class FacultyPPQDataService extends ProQuestAPIService {
  async getAll(): Promise<FacultyPPQDataDTO[]> {
    const response = await this.GetAsync<FacultyPPQDataDTO[]>('facultyppq/getall');

    let facultyPPQData: FacultyPPQDataDTO[] = [];

    if (response.isSuccess) {
      facultyPPQData = response.data;

      facultyPPQData.forEach(data => {
        data.createdon = new Date(data.createdon);
        data.ppqsubmissiondate = new Date(data.ppqsubmissiondate);
      });
    }

    return facultyPPQData;
  }

  async getByFacultyId(facultyid: number | string): Promise<FacultyPPQDataDTO | null> {
    const response = await this.GetAsync<FacultyPPQDataDTO>(`facultyppq/getbyfacultyid/${facultyid}`);

    if (response.isSuccess) {
      const facultyPPQData = response.data;

      if (facultyPPQData && facultyPPQData.createdon) {
        facultyPPQData.createdon = new Date(facultyPPQData.createdon);
        facultyPPQData.ppqsubmissiondate = new Date(facultyPPQData.ppqsubmissiondate);
      }

      return facultyPPQData;
    }

    return null;
  }

  async getAllExceptions(): Promise<FacultyPPQDataDTO[]> {
    const response = await this.GetAsync<FacultyPPQDataDTO[]>('facultyppq/getallrateexceptions');

    if (response.isSuccess) {
      return response.data;
    }

    return [];
  }

  async getAllActionItems(): Promise<FacultyPPQDataDTO[]> {
    const response = await this.GetAsync<FacultyPPQDataDTO[]>('facultyppq/getallactionitems');

    if (response.isSuccess) {
      return response.data;
    }

    return [];
  }

  async startPPQBulkUpload(): Promise<BulkPPQResponseDTO[]> {
    CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

    const response = await this.PostAsync<any>('bulkppq/createbulkrecords', null);

    CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);

    if (response.isSuccess) {
      CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, response.message);
    } else {
      CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, response.message);
    }

    return [];
  }

  async getPPQBulkUploads(showSuccess: boolean = false): Promise<BulkPPQResponseDTO[]> {
    CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

    const response = await this.GetAsync<BulkPPQResponseDTO[]>('facultyppq/uploadbulkppqresult');
    CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);

    if (response.isSuccess) {
      if (showSuccess) {
        CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, response.message);
      }

      return response.data.map(bulkResponse => {
        if (bulkResponse.responsecode === "S004" || bulkResponse.responsecode === "S005") {
          bulkResponse.responseStatus = "Success";
        } else {
          bulkResponse.responseStatus = "Failed";
        }

        return bulkResponse;
      });
    }

    CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, response.message);
    return [];
  }

  async getFacultyPPQDataByField(rateupdateexception: FacultyPPQDataDTO): Promise<FacultyPPQDataDTO | null> {
    let response;
    if (rateupdateexception.facultyid === -1) {
      response = await this.GetAsync<FacultyPPQDataDTO>(`facultyppq/getbyfirstlastname/${rateupdateexception.firstname}/${rateupdateexception.lastname}`);
    }
    else if (rateupdateexception.firstname !== '?') {
      response = await this.GetAsync<FacultyPPQDataDTO>(`facultyppq/getbyfirstname/${rateupdateexception.firstname}`);
    }
    else if (rateupdateexception.lastname !== '?') {
      response = await this.GetAsync<FacultyPPQDataDTO>(`facultyppq/getbylastname/${rateupdateexception.lastname}`);
    }
    else if (rateupdateexception.facultyid !== 0) {
      response = await this.GetAsync<FacultyPPQDataDTO>(`facultyppq/getbyfacultyid/${rateupdateexception.facultyid}`);
    } else {
      const response = await this.GetAsync<FacultyPPQDataDTO>(`facultyppq/getbysearchfields/${rateupdateexception.firstname}/${rateupdateexception.lastname}/${rateupdateexception.facultyid}`);
      return response.data;
    }
    if (response) {
      return response.data;
    } else {
      return null;
    }
  }

  async getAllHCPByFirstName(): Promise<SelectModel[]> {
    const hcpProfiles = await this.getAll();

    return hcpProfiles.map((hcpProfile) => {
      const selectModel = new SelectModel();
      selectModel.text = hcpProfile.firstname;
      selectModel.value = hcpProfile.firstname;
      return selectModel;
    });
  }

  async getAllHCPByFirstLastName(): Promise<SelectModel[]> {
    const hcpProfiles = await this.getAll();

    return hcpProfiles.map((hcpProfile) => {
      const selectModel = new SelectModel();
      selectModel.text = `${hcpProfile.firstname} ${hcpProfile.lastname}`;
      selectModel.value = `${hcpProfile.firstname} ${hcpProfile.lastname}`;
      return selectModel;
    });
  }

  async getAllHCPByLastName(): Promise<SelectModel[]> {
    const hcpProfiles = await this.getAll();

    return hcpProfiles.map((hcpProfile) => {
      const selectModel = new SelectModel();
      selectModel.text = hcpProfile.lastname;
      selectModel.value = hcpProfile.lastname;
      return selectModel;
    });
  }

  async getAllHCPByFacultyId(): Promise<SelectModel[]> {
    const hcpProfiles = await this.getAll();

    return hcpProfiles.map((hcpProfile) => {
      const selectModel = new SelectModel();
      selectModel.text = hcpProfile.facultyid.toString();
      selectModel.value = hcpProfile.facultyid;
      return selectModel;
    });
  }

  async edit(rateupdateexception: FacultyPPQDataDTO): Promise<FacultyPPQDataDTO | null> {
    CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

    rateupdateexception.israteexception = true;
    const response = await this.PutAsync<FacultyPPQDataDTO>(`facultyppq/updaterateexception/${rateupdateexception.facultyid}`, rateupdateexception);

    CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
    if (response.isSuccess) {
      CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, response.message);
      return response.data;
    }
    else {
      CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, response.message);
      return null;
    }
  }

  async updateAdditionalComment(facultyid: number, additionalcomments: string): Promise<FacultyPPQDataDTO | null> {
    const response = await this.PutAsync<FacultyPPQDataDTO>(`facultyppq/updateadditionalcomment/${facultyid}`, { additionalcomments });

    if (response.isSuccess) {
      return response.data;
    }
    else {
      return null;
    }
  }

  async getTieredData(): Promise<QuestionnaireManagementFacultyDTO> {
    const response = await this.GetAsync<QuestionnaireManagementFacultyDTO>('facultyppq/gettierfacultywithppqdata');

    if (response.isSuccess) {
      response.data.facultyPPQData.forEach(data => {
        data.createdon = new Date(data.createdon);
        data.ppqsubmissiondate = new Date(data.ppqsubmissiondate);
      });
      return response.data;
    }

    return { faculties: [], facultyPPQData: [] };
  }

  async getPendingTieringData(): Promise<QuestionnaireManagementFacultyDTO> {
    const response = await this.GetAsync<QuestionnaireManagementFacultyDTO>('facultyppq/getpendingtierfacultywithppqdata');

    if (response.isSuccess) {
      response.data.facultyPPQData.forEach(data => {
        data.createdon = new Date(data.createdon);
        data.ppqsubmissiondate = new Date(data.ppqsubmissiondate);
      });
      return response.data;
    }

    return { faculties: [], facultyPPQData: [] };
  }
}

export default new FacultyPPQDataService();