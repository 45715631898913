import React from 'react';
import { Box, Grid } from '@material-ui/core';

import { AppConfigDTO } from "../../../Models";
import TextInputField from '../../../Components/TextInput/TextInputField';
import RedButton from '../../../Components/Button/RedButton';
import WhiteButton from '../../../Components/Button/WhiteButton';
import SingleAutoComplete from '../../../Components/Select/SingleAutoComplete';
import SelectModel from '../../../Components/Select/SelectModel';
import InlineDatePicker from "../../../Components/Date/InlineDatePicker";
import { AppConfigService } from '../../../Services';

interface Props {
    appConfig: AppConfigDTO;
    onCancelClick: ((event: React.MouseEvent<HTMLButtonElement>) => void);
    onSubmit: ((appConfig: AppConfigDTO) => void);
}
interface State {
    appConfig: AppConfigDTO;
    yesNoArr: SelectModel[];
}
class AppConfigForm extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            appConfig: props.appConfig,
            yesNoArr: [],
        };
    }

    componentDidMount() {
        const yesNoArr = AppConfigService.getYesNoForSelect();
        this.setState({ yesNoArr });
    }

    render() {
        const { appConfig, yesNoArr } = this.state;
        const { onCancelClick } = this.props;

        return (
            <Box>
                <form noValidate autoComplete="off">
                    <div>
                        <Grid
                            container spacing={7}
                            alignItems="center"
                            justify="flex-start"
                            style={{ padding: 10 }}
                        >
                            <Grid item xs={3}>
                                <TextInputField id="appconfigname" label="appconfigname" isReadonly
                                    value={appConfig.appconfigname} onChange={this.handleChange} />
                            </Grid>
                            <Grid item xs={3}>
                                <SingleAutoComplete id="appconfigvalue" label="appconfigvalue"
                                    selectedValue={appConfig.appconfigvalue} values={yesNoArr} onChange={this.handleSelectChange} />
                            </Grid>
                            <Grid item md={3}>
                                <InlineDatePicker id="honoraryrulechangedate" label="honoraryrulechangedate" selectedDate={appConfig.honoraryrulechangedate}
                                    isSquare={true} onChange={this.handleDateChange} />
                            </Grid>
                            <Grid item xs={3}>
                                <SingleAutoComplete id="newhonoraryrulevalue" label="newhonoraryrulevalue"
                                    selectedValue={appConfig.newhonoraryrulevalue} values={yesNoArr} onChange={this.handleSelectChange} />
                            </Grid>
                            <Grid item>
                                <WhiteButton label="Cancel" onClick={onCancelClick} style={{ marginRight: 20 }} />
                                <RedButton label="Update" onClick={this.handleSubmit} />
                            </Grid>
                        </Grid>
                    </div>
                </form>
            </Box>
        );
    }

    handleSelectChange = (selected: SelectModel, targetId?: string) => {
        if (targetId) {
            this.setState(prevState => {
                let appConfig: any = Object.assign({}, prevState.appConfig);
                appConfig[targetId] = selected.value;
                return { appConfig };
            });
        }
    }

    handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        if (event) {
            this.setState(prevState => {
                let appConfig: any = Object.assign({}, prevState.appConfig);
                appConfig[event.target.id] = event.target.value;
                return { appConfig };
            });
        }
    }

    handleDateChange = (selectedDate: Date | null, targetId?: string) => {
        if (selectedDate && targetId) {
            this.setState(prevState => {
                let appConfig: any = Object.assign({}, prevState.appConfig);
                appConfig[targetId] = selectedDate;
                return { appConfig };
            });
        }
    }

    handleSubmit = () => {
        const { appConfig } = this.state;
        this.props.onSubmit(appConfig);
    }

}

export default AppConfigForm;